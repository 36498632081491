import { graphql, useStaticQuery } from 'gatsby';

const useSiteMetadata = () => {
	const { site } = useStaticQuery(
		graphql`
      query SITE_METADATA_QUERY {
        site: directus{
          globalSeoData {
            title
            description
            featuredImage {
              id
            }
          }
        }
      }
    `
	);
	return site.globalSeoData;
};

export default useSiteMetadata;
