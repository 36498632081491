exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-page-js": () => import("./../../../src/pages/about-page.js" /* webpackChunkName: "component---src-pages-about-page-js" */),
  "component---src-pages-contact-page-js": () => import("./../../../src/pages/contact-page.js" /* webpackChunkName: "component---src-pages-contact-page-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-offers-page-js": () => import("./../../../src/pages/offers-page.js" /* webpackChunkName: "component---src-pages-offers-page-js" */),
  "component---src-pages-products-page-js": () => import("./../../../src/pages/products-page.js" /* webpackChunkName: "component---src-pages-products-page-js" */),
  "component---src-pages-search-results-js": () => import("./../../../src/pages/search-results.js" /* webpackChunkName: "component---src-pages-search-results-js" */),
  "component---src-templates-product-item-template-js": () => import("./../../../src/templates/ProductItemTemplate.js" /* webpackChunkName: "component---src-templates-product-item-template-js" */)
}

