import React, { useEffect } from 'react';

function MessengerChat() {
	useEffect(() => {
		window.fbAsyncInit = function () {
			window.FB.init({
				xfbml: true,
				version: 'v13.0',
			});
		};
		(function (d, s, id) {
			var js,
				fjs = d.getElementsByTagName(s)[0];
			if (d.getElementById(id)) return;
			js = d.createElement(s);
			js.id = id;
			js.src = 'https://connect.facebook.net/en_GB/sdk/xfbml.customerchat.js';
			fjs.parentNode.insertBefore(js, fjs);
		})(document, 'script', 'facebook-jssdk');
	});
	return (
		<>
			<div id="fb-root" />
			<div
				className="fb-customerchat"
				attribution="biz_inbox"
				page_id="100809412551420"
			/>
		</>
	);
}

export default MessengerChat;
