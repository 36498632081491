/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/prefer-default-export */
import "./src/styles/global.css"

import React from "react"
// import Layout from './src/components/layout';
import MessengerChat from "./src/components/FacebookMessenger"

export const wrapPageElement = ({ element }) => (
  <>
    {element}
    <MessengerChat />
  </>
)
