/* eslint-disable import/no-unresolved */
/* eslint-disable no-restricted-globals */
/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState } from "react"

import { Link, navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const NAVLINK_CLASSES =
  "my-1 py-3 px-3 text-center font-medium text-gray-600 border-b-4 border-white hover:border-green-700 md:mx-2 md:my-0"

// this link will be active when itself or deeper routes
// are current
// https://reach.tech/router/api/Link
// this is only active when the location pathname is exactly
// the same as the href.
const isActive = ({ isCurrent }) => {
  return isCurrent
    ? { className: `${NAVLINK_CLASSES} text-green-700 border-green-700` }
    : {}
}

const ExactNavLink = ({ children, ...rest }) => {
  return (
    <div className="flex justify-center">
      <Link className={NAVLINK_CLASSES} getProps={isActive} {...rest}>
        {children}
      </Link>
    </div>
  )
}

// this link will be active when itself or deeper routes
// are current
const isPartiallyActive = ({ isPartiallyCurrent }) => {
  return isPartiallyCurrent
    ? { className: `${NAVLINK_CLASSES} text-green-700 border-green-700` }
    : {}
}

const PartialNavLink = ({ children, ...rest }) => {
  return (
    <div className="flex justify-center">
      <Link className={NAVLINK_CLASSES} getProps={isPartiallyActive} {...rest}>
        {children}
      </Link>
    </div>
  )
}

const Navbar = ({ className }) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const [searchValue, setSearchValue] = useState("")

  const handleSearch = () => {
    setMenuOpen(false)
    navigate(`/search-results?s=${searchValue}`)
  }
  const handleKeyDown = event => {
    if (event.key === "Enter") {
      // 👇 Get input value
      handleSearch()
    }
  }
  return (
    <nav className={`fixed top-0 w-full z-30 bg-white shadow-xl ${className}`}>
      <div className="max-w-7xl px-4 sm:px-6 lg:px-8 mx-auto md:flex md:justify-between md:items-center">
        <div className="flex items-center justify-between py-2">
          <Link className="flex items-center gap-3" to="/">
            <StaticImage
              src="../img/ht_logo.png"
              alt="Hucknall Timber"
              className="w-8 h-8 sm:w-10 sm:h-10"
              layout="fixed"
              width={140}
              height={111}
              loading="eager"
              backgroundColor="transparent"
              placeholder="blurred"
            />
          </Link>
          <div className="pl-1 text-sm font-bold tracking-wide lg:text-xl">
            <span className="text-gray-800 font-semibold">Hucknall</span>
            <span className="text-green-700">&nbsp;Timber</span>
            <div className="flex">
              <div className="relative text-gray-600 focus-within:text-gray-400">
                <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                  <button
                    className="p-1 pt-2 focus:outline-none focus:shadow-outline"
                    type="submit"
                    onSubmit={handleSearch}
                  >
                    <svg
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      className="w-4 h-4"
                    >
                      <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                    </svg>
                  </button>
                </span>
                <input
                  defaultValue={searchValue}
                  type="search"
                  value={searchValue}
                  onKeyDown={handleKeyDown}
                  onChange={e => {
                    setSearchValue(e.target.value)
                  }}
                  name="q"
                  className="w-28 lg:w-40 h-7 text-xs text-black bg-white rounded-md pl-8 focus:outline-none focus:bg-white focus:text-gray-900"
                  placeholder="Search..."
                  autoComplete="off"
                />
              </div>
            </div>
          </div>

          {/* Mobile menu button */}
          <div className="flex md:hidden absolute right-2 top-2">
            <button
              type="button"
              className="text-gray-500 dark:text-gray-200 hover:text-gray-600 dark:hover:text-gray-400 focus:outline-none focus:text-gray-600 dark:focus:text-gray-400"
              aria-label="toggle menu"
              onClick={() => setMenuOpen(!menuOpen)}
            >
              <svg viewBox="0 0 24 24" className="w-6 h-6 fill-current">
                <path
                  fillRule="evenodd"
                  d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                />
              </svg>
            </button>
          </div>
        </div>

        {/* Mobile Menu open: "block", Menu closed: "hidden" */}
        <div
          className={`items-center ${menuOpen ? "block" : "hidden"} md:flex`}
        >
          <div className="flex flex-col w-full md:flex-row md:mx-6 md:my-2 items-center">
            <ExactNavLink
              to="/"
              onClick={() => {
                setMenuOpen(false)
              }}
            >
              Home
            </ExactNavLink>
            <PartialNavLink
              to="/products-page"
              onClick={() => {
                setMenuOpen(false)
              }}
            >
              Products
            </PartialNavLink>
            <PartialNavLink
              to="/offers-page"
              onClick={() => {
                setMenuOpen(false)
              }}
            >
              Offers
            </PartialNavLink>
            <ExactNavLink
              to="/about-page"
              onClick={() => {
                setMenuOpen(false)
              }}
            >
              About
            </ExactNavLink>
            <ExactNavLink
              to="/contact-page"
              onClick={() => {
                setMenuOpen(false)
              }}
            >
              Contact
            </ExactNavLink>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
